import {mapActions, mapGetters, mapMutations} from "vuex";
import mainButton from '@/components/main-button/index.vue';

import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email, sameAs} from "vuelidate/lib/validators";
//sections
export default {
  name: "register",
  mixins: [validationMixin],
  components:{
    mainButton,
  },
  data(){
    return{
      see: false,
      repeatSee: false,
      payload: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      password: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(8),
      },
      password_confirmation: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(8),
        sameAsPassword: sameAs('password')
      },
    },
  },
  created() {
    this.payload.token = this.$route.query.token
    this.payload.email = this.$route.query.email
  },
  computed:{
    passwordError() {
      const error = []
      if (!this.$v.payload.password.$dirty) {
        return error
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 8))
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    confirmPasswordError() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },

  methods:{
    ...mapActions({
      change: `auth/SET_NEW_PASSWORD`
    }),
    submitForm() {
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        console.log(this.payload);
        this.change(this.payload).then((result) => {
          this.$router.push({name: 'success-restore'}).catch(()=>{})
        }).catch(error => {
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for(const i in errors){
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}